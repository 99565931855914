import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { Store } from '../Store';

function Product(props) {
  const { product } = props;

  const { state } = useContext(Store);
  const isVideo =
    product.image.startsWith(
      'https://res.cloudinary.com/dwcvhe5i6/video/upload'
    ) ||
    product.image.startsWith(
      'https://milkywayfiasco.sfo2.cdn.digialoceanspaces.com/mad/video'
    ) ||
    product.image.startsWith('https://lfc.milkywayfiasco');

  return (
    <Card className="mb-3 small-container bg-white rounded-lg shadow-md transform hover:-translate-y-1 transition duration-300">
      <Link to={`/product/${product.slug}`}>
        <div className="">
          {isVideo ? (
            <video
              src={product.image}
              className="card-img-top rounded-t-lg"
              alt={product.name}
              poster={product.poster}
              controls
            />
          ) : (
            <img
              src={product.image}
              className="card-img-top rounded-t-lg"
              alt={product.name}
            />
          )}
        </div>
      </Link>
    </Card>
  );
}

export default Product;
