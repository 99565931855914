import React from 'react';
import ImageGrid from '../components/ImageGrid';
export default function InfiniteScreen() {
  return (
    <div>
      <p> Gameday 06-17-23</p>
      <p>
        {' '}
        Photo Credit - Jack Tompkins Pettigrew,
        <br /> Video Credit - Ashley Johnson
      </p>
      <ImageGrid />
    </div>
  );
}
