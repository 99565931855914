import { useEffect, useReducer, useContext } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { ExternalLink } from 'react-external-link';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import Product from '../components/Product';
import { Link } from 'react-router-dom';

import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function HomeScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: '',
  });
  const imageFiles = [];
  const numberOfImages = 486;
  for (let i = 1; i <= numberOfImages; i++) {
    const fileName = `image${i}.jpg`;
    const imagePath = `${process.env.PUBLIC_URL}/images/resized_images/${fileName}`;
    imageFiles.push(imagePath);
  }
  // const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get('/api/products');
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }

      // setProducts(result.data);
    };
    fetchData();
  }, []);
  // const [products, setProducts] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     dispatch({ type: 'FETCH_REQUEST' });
  //     try {
  //       const result = await axios.get('/api/products');
  //       dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
  //     } catch (err) {
  //       dispatch({ type: 'FETCH_FAIL', payload: err.message });
  //     }

  //     // setProducts(result.data);
  //   };
  //   fetchData();
  // }, []);
  return (
    <div>
      <Helmet>
        <title>Mad Dog Football</title>
      </Helmet>
      <div className="main">
        {!userInfo && (
          <h3 className="mb-3">
            {' '}
            to leave a comment or upload a photo please{' '}
            <Link to="/signin">sign in</Link>
          </h3>
        )}

        <div className="text-lg">
          <p className="indent">
            The Mad Dog in the Fog was a classic English pub that opened in
            October of 1989 in the bohemian, eclectic and sometimes sketchy
            Lower Haight neighborhood of San Francisco. Owners Stephen Sparks
            and Roger Howell had been footballing legends during their
            university days growing up in Birmingham, England, and they opened
            for business just a few days before the biggest earthquake to hit
            California since 1906 left their new pub completely unscathed. That
            bit of good fortune continued unabated throughout the 90’s and
            beyond as the Mad Dog rightly became famous as the best bar in the
            City for UK ex-pats, intellectuals, brawlers, engineers, designers,
            musicians, blue-collar workers, artists and beautiful women.
          </p>
          <p className="indent">
            Steve and Roger recruited the first Mad Dog football team from
            amongst the English, Irish and Scottish lads at the bar who had the
            game in their blood and the fire in their hearts. Although skeptical
            at first, they eventually realized that a handful of Americans knew
            and loved the same game they did, and some could even match them
            pint for pint after the match. The Mad Dog team improved year after
            year as increasingly better players from across the country and
            around the world appeared as if by magic. They understood that
            playing football for winning teams sponsored by the best bar in San
            Francisco was not the worst way to spend your free time.
          </p>
          <p className="indent">
            While some may believe that the original Mad Dog players would never
            have gotten a minute of playing time on the last and best version of
            the team (S.F Scots), to be fair, many of us are now in our 60's...
            Players across the decades have always shared a deep respect and
            affection for each other just because each generation has always
            surrounded itself with the very best people, and we all came up
            playing out of that same magical pub on Haight Street. In that
            sense, we've been the very same guys all along.
          </p>

          <p className="indent">
            The Michael Maloney Memorial match is a chance to reconnect with all
            of the wonderful friends we have made over the years playing and
            watching the beautiful game at the Mad Dog, and to pay our respects
            to those we have lost - most recently, Keith Golden and Jan Astill.
            Before them, Dan Todd and Michael Maloney. Their spirits are still
            strong in our hearts.
          </p>

          <p className="indent">
            The fact that the reunion always bears Maloney's name is both
            fitting and a product of sheer happenstance, like so much else in
            life. He got the naming rights by happening to be the first of us to
            pass away. He deserved the naming rights because of the
            extraordinary friend and human being he was to all of us who knew
            him. Maloney was the last SF cop to investigate the Zodiac serial
            killings, and he features in a documentary of that case produced by
            John Mikulenka. John kindly put together a few outtakes from that
            film to help us remember Michael exactly as we knew him.
          </p>
        </div>
        <div className="centered-container">
          <div className="products">
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="image-container">
                {products.map((product) => (
                  <div key={product._id} className="mb-3">
                    <Product product={product}></Product>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="m-3">
          <Link to="/infinite">
            <Button className="m-3 px-5">
              {' '}
              Hundreds of game day photos <h1>HERE</h1>
            </Button>
          </Link>
        </div>
        <div>
          <div className=" text-center">
            Youth soccer camps by Deejae Johnson and{' '}
            <div>
              <ExternalLink href="https://soccerinsight.com">
                <span>soccerinsight.com</span>
              </ExternalLink>
            </div>
          </div>
          <div className="m-3 text-center">
            {' '}
            Website brought to you in Color by
            <div className="mt-2 glow-parent">
              <ExternalLink href="https://milkywayfiasco.com">
                <div className="glow">
                  <img
                    src="/images/showmad.png"
                    alt="showtime"
                    height="auto"
                    width="320px"
                    className="rounded"
                  />
                </div>
              </ExternalLink>
            </div>
          </div>

          <div className="p-2 text-center"></div>
        </div>
      </div>
    </div>
  );
}
export default HomeScreen;
