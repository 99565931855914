import axios from 'axios';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';

import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { getError } from '../utils';
import { Store } from '../Store';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { toast } from 'react-toastify';

const reducer = (state, action) => {
  switch (action.type) {
    case 'REFRESH_PRODUCT':
      return { ...state, product: action.payload };
    case 'CREATE_REQUEST':
      return { ...state, loadingCreateReview: true };
    case 'CREATE_SUCCESS':
      return { ...state, loadingCreateReview: false };
    case 'CREATE_FAIL':
      return { ...state, loadingCreateReview: false };
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, product: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function ProductScreen() {
  let reviewsRef = useRef();

  const [comment, setComment] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  const params = useParams();
  const { slug } = params;

  const [{ loading, error, product, loadingCreateReview }, dispatch] =
    useReducer(reducer, {
      product: [],
      loading: true,
      error: '',
    });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get(`/api/products/slug/${slug}`);
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };
    fetchData();
  }, [slug]);

  const { state } = useContext(Store);
  const { userInfo } = state;

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!comment) {
      toast.error('Please enter comment');
      return;
    }
    try {
      const { data } = await axios.post(
        `/api/products/${product._id}/reviews`,
        { comment, name: userInfo.name },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      dispatch({
        type: 'CREATE_SUCCESS',
      });
      toast.success('Review submitted successfully');
      product.reviews.unshift(data.review);
      product.numReviews = data.numReviews;
      dispatch({ type: 'REFRESH_PRODUCT', payload: product });
      window.scrollTo({
        behavior: 'smooth',
        top: reviewsRef.current.offsetTop,
      });
    } catch (error) {
      toast.error(getError(error));
      dispatch({ type: 'CREATE_FAIL' });
    }
  };

  function getTimeDifference(timestamp) {
    const currentDate = new Date();
    const targetDate = new Date(timestamp);
    const timeDifference = currentDate.getTime() - targetDate.getTime();
    const oneMinute = 1000 * 60;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const oneWeek = oneDay * 7;

    if (timeDifference < oneMinute) {
      return 'just now';
    } else if (timeDifference < oneHour) {
      const minutes = Math.floor(timeDifference / oneMinute);
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    } else if (timeDifference < oneDay) {
      const hours = Math.floor(timeDifference / oneHour);
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (timeDifference < oneWeek) {
      const days = Math.floor(timeDifference / oneDay);
      if (days === 1) {
        return 'yesterday';
      } else {
        return `${days} day${days === 1 ? '' : 's'} ago`;
      }
    } else {
      return targetDate.toLocaleDateString();
    }
  }

  const isVideo =
    product.image &&
    (product.image.startsWith(
      'https://res.cloudinary.com/dwcvhe5i6/video/upload'
    ) ||
      product.image.startsWith('https://milkywayfiasco') ||
      product.image.startsWith('https://lfc.milkywayfiasco'));

  return (
    <div>
      <Row>
        <Col md={6}>
          {isVideo ? (
            <video
              src={product.image}
              className="card-img-top rounded-t-lg"
              alt={product.name}
              controls
            />
          ) : (
            <img className="img-large" src={product.image} alt={product.name} />
          )}
        </Col>
        <Col md={3}>
          <ListGroup variant="flush">
            <Helmet>
              <title>mad dog football </title>
            </Helmet>
          </ListGroup>
        </Col>
      </Row>
      <div className="my-3">
        <div className="mb-3">
          <ListGroup>
            {product.reviews && product.reviews.length > 0 && (
              <>
                <h2 ref={reviewsRef}>What has been said already....</h2>
                <MessageBox></MessageBox>
              </>
            )}
            {product.reviews &&
              Array.isArray(product.reviews) &&
              product.reviews.map((review) => (
                <ListGroup.Item key={review._id}>
                  <strong>{review.name}</strong>
                  <p>{getTimeDifference(review.createdAt)}</p>
                  <p>{review.comment}</p>
                </ListGroup.Item>
              ))}
          </ListGroup>
          <div className="my-3">
            {userInfo ? (
              <form onSubmit={submitHandler}>
                <h2>Add a comment here</h2>

                <FloatingLabel
                  controlId="floatingTextarea"
                  label="Comments"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </FloatingLabel>

                <div className="mb-3">
                  <Button disabled={loadingCreateReview} type="submit">
                    Submit
                  </Button>
                  {loadingCreateReview && <LoadingBox></LoadingBox>}
                </div>
              </form>
            ) : (
              <MessageBox>
                Please{' '}
                <Link to={`/signin?redirect=/product/${product.slug}`}>
                  Sign In
                </Link>{' '}
                to chime in
              </MessageBox>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductScreen;
