import { useState } from 'react';
import Masonry from 'react-masonry-css';
import 'react-masonry-css/dist/react-masonry-css.cjs.js';

const ImageGrid = () => {
  const imageFolder = '/images/resized_images'; // Folder path containing the images
  const imageCount = 486; // Total number of images
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const generateImageGrid = () => {
    const images = [];

    for (let i = 1; i <= imageCount; i++) {
      const paddedIndex = i.toString().padStart(4, '0'); // Add leading zeros to match image names
      const imagePath = `${imageFolder}/${paddedIndex}_resized.jpg`;
      images.push(
        <div
          key={i}
          className="image-item"
          onClick={() => handleImageClick(imagePath)}
        >
          <img src={imagePath} alt={`footy-pics ${i}`} />
        </div>
      );
    }

    return images;
  };

  return (
    <>
      <Masonry
        breakpointCols={{
          default: 4,
          1200: 3,
          900: 2,
          600: 2, // Adjusted breakpoint for iPhones (two columns)
        }}
        className="image-grid"
        columnClassName="image-grid-column"
      >
        {generateImageGrid()}
      </Masonry>

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img
              src={selectedImage}
              alt="selected footy_pic"
              className="modal-image"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageGrid;
