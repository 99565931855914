import { useEffect, useReducer, useContext } from 'react';
import axios from 'axios';

import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import Product from '../components/Product';

import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function GalleryScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: '',
  });

  // const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get('/api/products');
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }

      // setProducts(result.data);
    };
    fetchData();
  }, []);
  // const [products, setProducts] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     dispatch({ type: 'FETCH_REQUEST' });
  //     try {
  //       const result = await axios.get('/api/products');
  //       dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
  //     } catch (err) {
  //       dispatch({ type: 'FETCH_FAIL', payload: err.message });
  //     }

  //     // setProducts(result.data);
  //   };
  //   fetchData();
  // }, []);
  return (
    <div>
      <Helmet>
        <title>Mad Dog Football</title>
      </Helmet>
      <div className="main mt-4">
        <div className="centered-container">
          <div className="products">
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="image-container">
                {products.map((product) => (
                  <div key={product._id} className="mb-3">
                    <Product product={product}></Product>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="p-2 text-center"></div>
      </div>
    </div>
  );
}
export default GalleryScreen;
